import React from "react"
import { graphql } from "gatsby"
import moment from "moment"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import SEO, { seoPropsMapping } from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const NewsPage = ({ data }) => {
  const {
    short_title,
    news_source,
    publish_date,
    article_content,
    resource_link,
    tile_image_for_news_list__required_,
  } = data.prismicNewsEntry.data

  const seo_description =
    article_content.text.length > 160
      ? `${article_content.text.substring(0, 160)}...`
      : article_content.text

  const seoProps = seoPropsMapping(data.prismicNewsEntry.data, {
    seo_title: short_title,
    seo_description,
    image: tile_image_for_news_list__required_ && tile_image_for_news_list__required_.fixed.src,
  })

  return (
    <Layout>
      <SEO {...seoProps} />
      <Header title={`Vindicia in the News`} />
      <section className="uk-section">
        <div className="uk-container">
          <div className="">
            <p className="uk-heading-small">{short_title}</p>
            <p>
              {moment(publish_date).format("MMM D, YYYY")} | By {news_source}
            </p>
            <div className="uk-width-expand">
              <div
                className="prismic-content uk-margin-small-top"
                dangerouslySetInnerHTML={{ __html: article_content.html }}
              />
              {resource_link && (
                <a
                  className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin"
                  href={resource_link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read complete article
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(NewsPage)

export const newsDetail = graphql`
  query newsDetail($uid: String) {
    prismicNewsEntry(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        canonical_url {
          url
        }
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        custom_page_title
        short_title
        news_source
        publish_date
        resource_link {
          target
          url
        }
        tile_image_for_news_list__required_ {
          fixed(width: 540) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        article_content {
          html
          text
        }
      }
    }
  }
`
